import React, { FC, } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import variables from 'styles/variables';
import { OrganisationDetails as IOrganisationDetails } from 'types/dbSchema/organisation';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UserPosition } from 'types/dbSchema/userPositions';

export interface DetailsProps {
  organisationDetails: IOrganisationDetails;
}

const OrganisationDetails: FC<DetailsProps> = ({ organisationDetails }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('/settings/organisation/organisation-settings/edit');
  };

  return (
    <Stack height='calc(100% - 154px)' sx={{ overflowY: 'auto' }}>
      <Stack minHeight="fit-content" justifyContent="flex-start" alignItems="flex-start" padding='24px' gap='24px'>
        <Stack width="100%" alignItems='flex-end' height='fit-content'>
          <Button variant="contained" color="primary" sx={{ width: 'fit-content' }} startIcon={<EditOutlined />} onClick={handleEdit}>
            Edit
          </Button>
        </Stack>
        <Stack width='100%' gap='24px' flexDirection='row'>
          <LeftStack>
            <Details organisationDetails={organisationDetails} />
            <ContactDetails organisationDetails={organisationDetails} />
            <OwnerDetails organisationDetails={organisationDetails} />
            <BankingDetails organisationDetails={organisationDetails} />
          </LeftStack>
          <RightStack >
            <SubscriptionInformation organisationDetails={organisationDetails} />
            <EmailVerification organisationDetails={organisationDetails} />
          </RightStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Details: FC<DetailsProps> = ({ organisationDetails }) => {

  if (!organisationDetails) return null;

  return (
    <StyledStack>
      <LineHeader>
        <Box width='100%'>
          <Typography variant="subtitle1">Organisation Details</Typography>
        </Box>
      </LineHeader>
      <Line alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Name
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.name}
          </Typography>
        </Stack>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            ABN
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} >
            {organisationDetails.abn}
          </Typography>
        </Stack>
      </Line>
      <Line noBottomBorder alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            NDIS ID
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main}>
            {organisationDetails.ndisOrganisationId}
          </Typography>
        </Stack>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Website
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main}>
            {organisationDetails.website}
          </Typography>
        </Stack>
      </Line>
    </StyledStack>
  );
};

const ContactDetails: FC<DetailsProps> = ({ organisationDetails }) => {

  if (!organisationDetails) return null;

  return (
    <StyledStack>
      <LineHeader>
        <Box width='100%'>
          <Typography variant="subtitle1">Contact Details</Typography>
        </Box>
      </LineHeader>
      <Line alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Phone
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.phone}
          </Typography>
        </Stack>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Email Address
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.email}
          </Typography>
        </Stack>
      </Line>
      <Line noBottomBorder alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Business Address
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.businessAddress?.streetAddress}
            {organisationDetails.businessAddress?.suburb}
            {organisationDetails.businessAddress?.state}
            {organisationDetails.businessAddress?.postCode}
          </Typography>
        </Stack>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            PO Box
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.postalAddress?.streetAddress}
            {organisationDetails.postalAddress?.suburb}
            {organisationDetails.postalAddress?.state}
            {organisationDetails.postalAddress?.postCode}
          </Typography>
        </Stack>
      </Line>
    </StyledStack>
  );
};

const OwnerDetails: FC<DetailsProps> = ({ organisationDetails }) => {

  if (!organisationDetails) return null;

  return (
    <StyledStack>
      <LineHeader>
        <Box width='100%'>
          <Typography variant="subtitle1">Owner Details</Typography>
        </Box>
      </LineHeader>
      <Line noBottomBorder>
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          {organisationDetails.owner?.profilePicture ? (
            <img src={organisationDetails.owner.profilePicture} alt="profile" style={{ width: '42px', height: '42px', borderRadius: '32px', objectFit: 'cover' }} />
          ) : (
            <Box width='42px' height='42px' borderRadius='32px' display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#E0E0E0' }}>
              <Typography variant='body2' color={variables.colors.text.secondary} fontWeight='600'>
                {organisationDetails?.owner &&
                  `${organisationDetails.owner?.fullName.firstName[0].toUpperCase()} ${organisationDetails.owner?.fullName.lastName[0].toUpperCase()}`}
              </Typography>
            </Box>
          )}
        </Box>
        <Stack gap='4px' width='100%'>
          <Typography variant='body1' fontSize='18px' fontWeight='600'>
            Name
          </Typography>
          <Stack gap='4px' width='100%' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Position
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
              {organisationDetails?.owner && (organisationDetails.owner?.position as UserPosition).name}
            </Typography>
          </Stack>
          <Stack gap='4px' width='100%' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Mobile
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {organisationDetails?.owner && organisationDetails.owner?.primaryMobile}
            </Typography>
          </Stack>
          <Stack gap='4px' width='100%' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Email Address
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {organisationDetails?.owner && organisationDetails.owner?.workEmail}
            </Typography>
          </Stack>
        </Stack>
      </Line>
    </StyledStack>
  );
};

const BankingDetails: FC<DetailsProps> = ({ organisationDetails }) => {

  if (!organisationDetails) return null;

  return (
    <StyledStack>
      <LineHeader>
        <Box width='100%'>
          <Typography variant="subtitle1">Banking Details</Typography>
        </Box>
      </LineHeader>
      <Line alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Account Name
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.bankAccountName}
          </Typography>
        </Stack>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            BSB
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.bsb}
          </Typography>
        </Stack>
      </Line>
      <Line noBottomBorder alignItems='flex-start'>
        <Stack width='100%' gap='8px'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Account Number
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {organisationDetails.bankAccountNumber}
          </Typography>
        </Stack>
      </Line>
    </StyledStack>
  );
};

const SubscriptionInformation: FC<DetailsProps> = ({ organisationDetails }) => {
  const handleEdit = () => {
    alert('navigate to stripe billing portal');
  };

  return (
    <AsideInfo>
      <Stack
        gap='8px'
        width='100%'
        flexDirection='row'
        padding='16px 24px'
        justifyContent='space-between'
        sx={{
          backgroundColor: '#5C068C0A',
          boxSizing: 'border-box',
          borderRadius: '8px 8px 0 0',
          borderBottom: '1px solid #D4A3E3'
        }}
      >
        <Typography variant='subtitle1'>
          Subscription Information
        </Typography>
        <EditOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
      </Stack>
      <Line justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' gap='8px' width='fit-content'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            License
          </Typography>
          <Typography variant='subtitle2'>
          </Typography>
        </Stack>
      </Line>
      <Line>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Status
        </Typography>
        <Typography variant='subtitle2' color='variables.colors.text.main'>
        </Typography>
      </Line>
      <Line>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Credit Card No.
        </Typography>
        <Typography variant='body2'>
        </Typography>
      </Line>
      <Line noBottomBorder>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Billing Contact
        </Typography>
        <Typography variant='body2'>
        </Typography>
      </Line>
    </AsideInfo>
  );
};

const EmailVerification: FC<DetailsProps> = ({ organisationDetails }) => {
  const handleEdit = () => {
    alert('handle email verification edit');
  };

  return (
    <AsideInfo>
      <Stack
        gap='8px'
        width='100%'
        flexDirection='row'
        padding='16px 24px'
        justifyContent='space-between'
        sx={{
          backgroundColor: '#5C068C0A',
          boxSizing: 'border-box',
          borderRadius: '8px 8px 0 0',
          borderBottom: '1px solid #D4A3E3'
        }}
      >
        <Typography variant='subtitle1'>
          Email Verification
        </Typography>
        <EditOutlinedIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
      </Stack>
      <Line justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' gap='8px' width='fit-content'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Domain
          </Typography>
          <Typography variant='subtitle2'>
          </Typography>
        </Stack>
      </Line>
      <Line>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Status
        </Typography>
        <Typography variant='subtitle2' color='variables.colors.text.main'>
        </Typography>
      </Line>
      <Line>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Credit Card No.
        </Typography>
        <Typography variant='body2'>
        </Typography>
      </Line>
      <Line noBottomBorder>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Billing Contact
        </Typography>
        <Typography variant='body2'>
        </Typography>
      </Line>
    </AsideInfo>
  );
};

const AsideInfo = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #D4A3E3;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const LeftStack = styled(Stack)`
  width: 60%;
  gap: 24px;
  @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const RightStack = styled(Stack)`
  width: 40%;
  min-width: 400px;
  height: 100%;
  gap: 24px;
    @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default OrganisationDetails;