import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Button } from '@mui/material';
import ServiceRegionForm from 'components/features/Forms/ServiceRegionForm';
import { useNavigate } from 'react-router-dom';

const Actions: FC = () => {
  const navigate = useNavigate();

  const handleNewClaimFile = () => {
    navigate('/billing/ndis-claims/create');
  };

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={handleNewClaimFile}
      startIcon={<AddOutlinedIcon />}
      sx={{ minWidth: 'fit-content', height: '100%' }}
    >
      Create Claim File
    </Button>
  );
};

export default Actions;