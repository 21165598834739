import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import variables from 'styles/variables';
import { Box, Button, Stack, Typography } from '@mui/material';
import AuthenticationButton from 'components/common/AuthenticationButton';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import TransferOwnership from '../TransferOwnership';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import useCurrentUser from 'hooks/useCurrentUser';
import { UserPosition } from 'types/dbSchema/userPositions';
import ViewUserProfileModal from '../Views/ViewUserProfileModal';

const Avatar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<SVGSVGElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [, setModalState] = useAtom(modalStateAtom);
  const [currentUser] = useCurrentUser();

  // This is used to close the dropdown when clicking outside of it and outside the button.
  // The reason we need to check if the button is clicked is because the filter button handles it's own click event and we don't want to trigger both.
  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  // This is used to open and close the dropdown when clicking the button
  const handleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  // Cleanup the event listener when the component is unmounted
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: TransferOwnership,
      props: {}
    }));
  }, [setModalState]);

  const handleTransferOwnership = () => {
    setIsOpen(false);
    handleModalOpen();
  };

  // Function to open/close the modal.
  const userProfileOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ViewUserProfileModal,
      props: {}
    }));
  }, [setModalState]);


  const handleUserProfileOpen = () => {
    setIsOpen(false);
    userProfileOpen();
  };

  return (
    <Stack position='relative' width='41px'>
      <AccountCircleIcon
        ref={buttonRef}
        onClick={handleDropdown}
        sx={{
          color: variables.colors.primary.darker,
          height: '100%',
          width: 'auto',
          cursor: 'pointer'
        }} />
      {isOpen && (
        <Dropdown ref={dropdownRef}>
          <Stack padding='20px'>
            <AccountCircleIcon
              sx={{
                color: variables.colors.primary.darker,
                height: '60px',
                width: '60px',
              }} />
            <Typography variant='h6' sx={{ color: variables.colors.darkNeutral.darker }}>
              {currentUser?.fullName.firstName} {currentUser?.fullName.lastName}
            </Typography>
            <Typography variant='subtitle2' fontWeight='500' sx={{ color: variables.colors.text.secondary, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {currentUser?.workEmail}
            </Typography>
            <Typography variant='subtitle2' fontWeight='500' sx={{ color: variables.colors.primary.main }}>
              {(currentUser?.position as UserPosition)?.name}
            </Typography>
          </Stack>
          <Stack padding='8px' borderTop='1px solid #0000001F'>
            <Button
              variant='text'
              startIcon={<PersonOutlineOutlinedIcon />}
              onClick={handleUserProfileOpen}
              sx={{
                color: variables.colors.icon.standard,
                justifyContent: 'flex-start',
              }}
            >
              Profile
            </Button>
            <Button
              variant='text'
              startIcon={<SwapHorizOutlinedIcon />}
              onClick={handleTransferOwnership}
              sx={{
                color: variables.colors.icon.standard,
                justifyContent: 'flex-start',
              }}
            >
              Transfer Ownership
            </Button>
          </Stack>
          <Box padding='16px' borderTop='1px solid #0000001F'>
            <AuthenticationButton type='login logout' prompt='login' startIcon={<LogoutIcon />} />
          </Box>
        </Dropdown>
      )}
    </Stack>
  );
};

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  border-radius: 12px;
  z-index: 1000;
  width: 280px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 0px 1px 0px rgba(55, 65, 81, 0.20), 0px 4px 12px 0px rgba(55, 65, 81, 0.15);
`;

export default Avatar;