import axiosInstance from "api/config";
import { ParticipantAndContactLink, ParticipantAndContactLinkList } from "types/dbSchema/participantContact";

// Returns a list of service provider contacts for the given organisation ID and participant ID
export const listParticipantServiceProviderContacts = async (
  organisationId: string,
  participantId: string,
  page?: number,
  pageSize?: number
): Promise<ParticipantAndContactLinkList> => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/serviceprovidercontacts`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing Service Provider Contacts for the organisation and participant', error);
    throw error;
  }
};

// Get a Service Provider Contact by ID, for the given organisation ID and participant ID
export const getParticipantServiceProviderContactByID = async (
  organisationId: string,
  serviceProviderContactId: string,
  participantId: string
): Promise<ParticipantAndContactLink> => {
  try {
    if (!serviceProviderContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/serviceprovidercontacts/${serviceProviderContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the Service Provider Contact for the participant', error);
    throw error;
  }
};

// Link an Service Provider Contact to a participant
export const linkParticipantToServiceProviderContact = async (
  organisationId: string,
  serviceProviderContactId: string,
  participantId: string,
  data: any
): Promise<ParticipantAndContactLink> => {
  try {
    if (!serviceProviderContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/serviceprovidercontacts/${serviceProviderContactId}/linkcontact`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error linking the Service Provider Contact to the participant', error);
    throw error;
  }
};

// Update the link between a participant and an Service Provider Contact
export const updateParticipantServiceProviderContactLink = async (
  organisationId: string,
  serviceProviderContactId: string,
  participantId: string,
  data: any
): Promise<ParticipantAndContactLink> => {
  try {
    if (!serviceProviderContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/serviceprovidercontacts/${serviceProviderContactId}/updatecontactlink`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the Service Provider Contact link', error);
    throw error;
  }
};

// Unlink a Service Provider Contact
export const unlinkParticipantFromServiceProviderContact = async (
  organisationId: string,
  serviceProviderContactId: string,
  participantId: string
): Promise<ParticipantAndContactLink> => {
  try {
    if (!serviceProviderContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/serviceprovidercontacts/${serviceProviderContactId}/unlinkcontact`);
    return response.data;
  } catch (error) {
    console.error('There was an error unlinking the Service Provider Contact from the participant', error);
    throw error;
  }
};