import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InvoiceBatchFile } from 'types/dbSchema/invoiceBatches';

export interface ViewIconProps {
  invoiceBatch: InvoiceBatchFile;
}

const ViewIcon: FC<ViewIconProps> = ({ invoiceBatch }) => {
  const navigate = useNavigate();

  return (
    <StyledBox onClick={() => navigate(`/billing/invoice-batches/view/${invoiceBatch.id}`)}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;