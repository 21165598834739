import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { Typography } from '@mui/material';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { Option } from '../types';
import FilterWithDropdown from '..';
import { listParticipants } from 'api/organisations/participants';
import { Participant } from 'types/dbSchema/participants';

const ParticipantFilter = ({
  selectedFilters,
  setSelectedFilters,
  filterOpen,
  setFilterOpen,
  label
}: any) => {
  const [organisations] = useUserOrganisations();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<Option[]>([]);

  // useInfiniteQuery is a hook that allows you to fetch data on scroll
  // This function handles the fetching of users in the organisation
  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ['participants-infinite', organisations[0]?.organisation.globalId, 100],
    queryFn: ({ pageParam = 0 }) => listParticipants(organisations[0].organisation.globalId, null, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.userProfiles,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) {
        return undefined; // No more pages to load
      }
      return allPages.length;
    },
  });

  // When the data is fetched, map the positions to the options
  // The label is JSX so that we can customise the look of the options
  useEffect(() => {
    if (data) {
      const newOptions = data.pages.flatMap(page =>
        page.items.map((participant: Participant) => ({
          value: participant.id!,
          label: (
            <Typography key={participant.id} variant='body2'>
              {participant.fullName.firstName} {participant.fullName.lastName}
            </Typography>
          ),
        }))
      );
      setOptions(newOptions);
    }
  }, [data]);

  return (
    <FilterWithDropdown
      filter={{
        field: 'linkedParticipants',
        name: label,
        options,
      }}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      selectedFilters={selectedFilters}
      setFilterOpen={setFilterOpen}
      setSelectedFilters={setSelectedFilters}
      filterOpen={filterOpen}
      searchValue={searchText}
      setSearchValue={setSearchText}
    />
  );
};

export default ParticipantFilter;