import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import UserProfileLookupField from 'components/common/LookupInputField/UserProfileLookupField';
import { phoneValidationRegex } from 'utils/helpers';

export interface ContactDetailsProps {
  control: Control<Participant>;
  errors: FieldErrors<Participant>;
}

const ContactDetails: FC<ContactDetailsProps> = ({ control, errors }) => {
  return (
    <ContactDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Contact Details
      </Typography>
      <Row>
        <Controller
          name="mobile"
          control={control}
          rules={{
            required: 'Mobile Number is required',
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='primaryMobile'
              label="Mobile Number"
              error={errors.mobile ? true : false}
              errorText={errors.mobile?.message}
              isMandatory
            />}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            pattern: {
              value: phoneValidationRegex,
              message: 'Please enter a valid Australian phone number',
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label="Phone"
              error={errors.phone ? true : false}
              errorText={errors.phone?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='email'
              label="Email"
              error={errors.email ? true : false}
              errorText={errors.email?.message}
              isMandatory
            />}
        />
        <Controller
          name="additionalPersonalEmail"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='additionalPersonalEmail'
              label="Additional Personal Email"
              error={errors.additionalPersonalEmail ? true : false}
              errorText={errors.additionalPersonalEmail?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="referredBy"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z\s]+$/, message: 'Invalid Referral Name' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='referredBy'
              label='Referred by'
              error={errors.referredBy ? true : false}
              errorText={errors.referredBy?.message}
            />}
        />
        <Controller
          name="assignedTo"
          control={control}
          rules={{
            required: 'Assigned To is required',
          }}
          render={({ field }) =>
            <UserProfileLookupField
              {...field}
              id='assignedTo'
              label='Assigned To'
              validationError={errors.assignedTo ? true : false}
              errorText={errors.assignedTo?.message}
              isMandatory
              placeholder='Select'
            />}
        />
      </Row>
    </ContactDetailsStack>
  );
};

const ContactDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default ContactDetails;