import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import OrganisationDetails from './OrganisationDetails';
import Tabs from 'components/common/Tabs';
import SubscriptionDetails from './SubscriptionDetails';
import PaymentHistory from './PaymentHistory';
import { OrganisationDetails as IOrganisationDetails } from 'types/dbSchema/organisation';

export interface OrganisationSettingsProps {
  organisationDetails: IOrganisationDetails;
}

const OrganisationSettings: FC<OrganisationSettingsProps> = ({ organisationDetails }) => {
  return (
    <Stack height='100%' sx={{ backgroundColor: 'white' }}>
      <Stack padding='24px 24px 16px 24px'>
        <CustomBreadcrumbs />
        <Typography variant="h4">Organisation Settings</Typography>
      </Stack>
      <Tabs
        tabs={[
          {
            label: 'Organisation Details',
            content: <OrganisationDetails organisationDetails={organisationDetails} />,
            requiredPermissions: [],
          },
          {
            label: 'Payment History',
            content: <PaymentHistory />,
            requiredPermissions: [],
          },
        ]}
      />
    </Stack>
  );
};

export default OrganisationSettings;