import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import Actions from './Actions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/common/Tooltip';
import { ControlsProps } from '../../types';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds
}) => {
  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Stack gap='4px' flexDirection='row' alignItems='center'>
            <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
              Alerts
            </Typography>
            <Tooltip
              toolTipTitle='Participant Alerts'
              toolTipText='Participant alerts provide critical information that must be seen by web and mobile users.'
              toolTipTriggerComponent={<InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />}
            />
          </Stack>
          <Stack flexDirection='row' gap='16px' height='36px'>
            <Actions />
          </Stack>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export default Controls;