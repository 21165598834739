import React, { FC } from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { modalStateAtom } from "atoms";
import { Box, Button, Stack, Typography } from "@mui/material";
import variables from 'styles/variables';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { unlinkParticipantFromExternalContact } from 'api/organisations/participants/externalcontacts';
import { useNavigate, useParams } from 'react-router-dom';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { AllContactListView } from 'components/features/SearchAllContactsField/types';
import { unlinkParticipantFromServiceProviderContact } from 'api/organisations/participants/serviceprovidercontacts';
import { unlinkParticipantFromParticipantContact } from 'api/organisations/participants/participantcontacts';

export const UnlinkIcon = ({ contact }: { contact: AllContactListView }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        contact,
        participantId: id,
      }
    }));
  }, [setModalState]);


  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <LinkOffIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};

export interface UnlinkModalProps {
  contact: AllContactListView;
  participantId: string;
  backAfterUnlink?: boolean;
}

export const UnlinkModal: FC<UnlinkModalProps> = ({ contact, participantId, backAfterUnlink = false }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const navigate = useNavigate();

  const handleUnlink = async () => {
    if (!contact.id || !participantId) {
      showError('Participant or contact not found');
      return;
    }

    try {
      if (contact.contactType === 'Contact') {
        await unlinkParticipantFromExternalContact(
          organisations[0].organisation.globalId,
          contact.id,
          participantId
        );
      } else if (contact.contactType === 'ServiceProvider') {
        await unlinkParticipantFromServiceProviderContact(
          organisations[0].organisation.globalId,
          contact.id,
          participantId
        );
      } else if (contact.contactType === 'Participant') {
        await unlinkParticipantFromParticipantContact(
          organisations[0].organisation.globalId,
          contact.id,
          participantId
        );
      }

      setInvalidateData((prev) => ({
        ...prev,
        'external-contacts': true,
        'service-provider-contacts': true,
        'participant-contacts': true
      }));

      closeModal();
      backAfterUnlink && navigate(-1);

    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <LinkOffIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Unlink this contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Are you sure you want to remove this contact from the participant’s contacts list?
          </Typography>
          <Typography component='div' variant='body2' color={variables.colors.text.secondary} sx={{ fontStyle: 'italic' }}>
            <Box component='span' sx={{ fontWeight: 700 }} color={variables.colors.text.secondary}>Note: </Box>this action does not delete the contact record from the system, it only removes the link with the participant.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleUnlink}>Unlink</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;