import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Actions: FC = () => {
  const navigate = useNavigate();

  const handleNewPayfile = () => {
    navigate('/billing/payfiles/create');
  };

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={handleNewPayfile}
      startIcon={<AddOutlinedIcon />}
      sx={{ minWidth: 'fit-content', height: '100%' }}
    >
      Create Payfile
    </Button>
  );
};

export default Actions;