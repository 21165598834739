import axiosInstance from "api/config";
import { ParticipantAndContactLink, ParticipantAndContactLinkList } from "types/dbSchema/participantContact";

// // Returns a list of Participant Contacts for the given organisation ID and participant ID
// export const listParticipantContacts = async (
//   organisationId: string,
//   participantId: string,
//   page?: number,
//   pageSize?: number
// ): Promise<ParticipantAndContactLinkList> => {
//   try {
//     if (!organisationId || !participantId) throw new Error('Organisation ID, page, and page size are required');

//     const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/participantcontacts`, {
//       params: {
//         page,
//         pageSize
//       }
//     });

//     return response.data;
//   } catch (error) {
//     console.error('There was an error listing Participant Contacts for the organisation and participant', error);
//     throw error;
//   }
// };

// Get a Participant Contact by ID, for the given organisation ID and participant ID
export const getParticipantsParticipantContactByID = async (
  organisationId: string,
  participantContactId: string,
  participantId: string
): Promise<ParticipantAndContactLink> => {
  try {
    if (!participantContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ParticipantContact ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/participantcontacts/${participantContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the Participant Contact for the participant', error);
    throw error;
  }
};

// Link a Participant to a participant
export const linkParticipantToParticipant = async (
  organisationId: string,
  participantContactId: string,
  participantId: string,
  data: any
): Promise<ParticipantAndContactLink> => {
  try {
    if (!participantContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ParticipantContact ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/participantcontacts/${participantContactId}/linkcontact`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error linking the Participant Contact to the participant', error);
    throw error;
  }
};

// Update the link between a participant and an Participant
export const updateParticipantParticipantLink = async (
  organisationId: string,
  participantContactId: string,
  participantId: string,
  data: any
): Promise<ParticipantAndContactLink> => {
  try {
    if (!participantContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ParticipantContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/participantcontacts/${participantContactId}/updatecontactlink`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the Participant Contact link', error);
    throw error;
  }
};

// Unlink a Participant Contact
export const unlinkParticipantFromParticipantContact = async (
  organisationId: string,
  participantContactId: string,
  participantId: string
): Promise<ParticipantAndContactLink> => {
  try {
    if (!participantContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ParticipantContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/participantcontacts/${participantContactId}/unlinkcontact`);
    return response.data;
  } catch (error) {
    console.error('There was an error unlinking the Participant Contact from the participant', error);
    throw error;
  }
};