import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { GeneralNote } from 'types/dbSchema/generalNotes';
import styled from 'styled-components';
import { Box } from '@mui/material';
import ViewGeneralNotes from 'components/features/Views/ViewGeneralNotes';

export interface ViewIconProps {
  generalNote: GeneralNote;
}

const ViewIcon: FC<ViewIconProps> = ({ generalNote }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleImportModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ViewGeneralNotes,
        props: {
          generalNote: generalNote,
        }
      });
    } else {
      closeModal();
    }
  };

  return (
    <StyledBox onClick={handleImportModalOpen}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;