import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { createFilterString } from 'utils/helpers';
import { listUsers } from 'api/organisations/users';
import { useNavigate } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { dummyNDISClaims } from 'utils/helpers/getDBData';

const InvoiceBatchesDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  const navigate = useNavigate();
  useInvalidateData('invoice-batches'); // If the data table has been flagged for invalidation, invalidate the query

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['invoice-batches', paginationModel, createFilterString(selectedFilters)],
    queryFn: () => dummyNDISClaims,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.invoiceBatches,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Invoice Batches'
        dataTableName='invoice-batches'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={() => navigate('/billing/invoice-batches/create')}
      />
    </Stack>
  );
};

export default InvoiceBatchesDataTable;