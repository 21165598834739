import { AboriginalOrTorresStraitIslander, Address, PaginationBase, Pronouns } from '..';
import { ChangeLogData } from '../../components/common/ChangeLog/types';
import { ExitReason, ParticipantStatus, PrimaryDisability } from './participantSettings';
import { ServiceType } from './schedulingSettings';
import { ServiceRegion } from './serviceRegions';
import { Tag } from './serviceTeamSettings';
import { FullName, NameAndID, User } from './userProfiles';

// TODO: remove the following when the lookup fields are implemented
export type SystemParticipantStatus = 'Active' | 'Inactive' | 'Pending' | 'Deceased';
export const systemParticipantStatuses: SystemParticipantStatus[] = ['Active', 'Inactive', 'Pending', 'Deceased'];
export interface Participant {
  id?: string; // Not present in POST
  salutation?: string;
  fullName: FullName;
  preferredName?: string;
  pronoun?: Pronouns;
  mobile: string;
  phone?: string;
  email: string;
  additionalPersonalEmail?: string;
  dateOfBirth: string; // "2024-07-02T02:52:51.560Z" This format
  gender: string;
  status: ParticipantStatus | string; // LOOKUP
  serviceRegion: ServiceRegion | string; // LOOKUP (When completed)
  homeAddress: Address; // LOOKUP
  otherAddress?: Address; // LOOKUP
  assignedTo: NameAndID | string; // LOOKUP: id of the user who is assigned to the participant
  profilePicture?: string;
  referredBy?: string;
  aboriginalOrTorresStraitIslander?: AboriginalOrTorresStraitIslander;
  // Care Needs Details
  primaryLanguage?: string;
  otherLanguage?: string;
  interpreterRequired?: boolean;
  primaryDisability?: PrimaryDisability | string; // LOOKUP
  otherDisability?: string;
  culturalRequirements?: string;
  allergies?: string;
  livingArrangements?: string;
  interests?: string;
  servicePreferences: Tag[] | string[]; // LOOKUP
  // Funding details
  ndisNumber: string;
  fundingProgram?: string;
  ndisSystem: 'PRODA' | 'PACE';
  changeLog: ChangeLogData[];
  isActive: boolean;
  exitDate?: string; // "2024-07-02T02:52:51.560Z" This format
  exitReason?: ExitReason | string; // LOOKUP
  deceasedDate?: string; // "2024-07-02T02:52:51.560Z" This format
  createdBy: NameAndID | string;
}

export type ParticipantList = PaginationBase<Participant>;

export interface ParticipantProfileCellProps {
  participant: Participant;
}

export const statuses = [
  {
    label: 'Active',
    value: 'Active',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
    fontColor: '#5C068C',
    backgroundColor: '#5C068C26',
  },
  {
    label: 'Pending',
    value: 'Pending',
    fontColor: '#C62828',
    backgroundColor: '#e5393533',
  },
  {
    label: 'Deceased',
    value: 'Deceased',
    fontColor: '#1F2937',
    backgroundColor: '#4b556314',
  },
];