import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import variables from 'styles/variables';
import { AllContactListView } from 'components/features/SearchAllContactsField/types';

export interface ViewIconProps {
  contact: AllContactListView;
}

const ViewIcon: FC<ViewIconProps> = ({ contact }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleViewContact = () => {
    if (contact.contactType === 'Contact') {
      navigate(`/participant-centre/contacts-console/view/${contact.id}`);
    } else if (contact.contactType === 'ServiceProvider') {
      navigate(`/resources/service-providers/view/${id}/contact/${contact.id}`);
    } else if (contact.contactType === 'Participant') {
      navigate(`/participant-centre/participants/view/${id}/contacts/${contact.id}`);
    }
  };

  return (
    <StyledBox onClick={handleViewContact}>
      <RemoveRedEyeOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;