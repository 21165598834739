import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewContact from 'components/features/Views/ViewContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getExternalContactByID } from 'api/organisations/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

const ViewContactPage: FC = () => {
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getExternalContactByID(organisations[0].organisation.globalId, id as string)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <ViewContact externalContact={currentDataPoint} />
  );
};

export default ViewContactPage;