import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterLookup/types';
import { OrganisationDetails } from 'types/dbSchema/organisation';
import { createFilterString } from 'utils/helpers';

// Checks if an organisation exists with the given ABN and NDIS ID
export const organisationExists = async (abn: string, ndisId: string) => {
  try {
    if (!abn || !ndisId) {
      throw new Error('ABN and NDIS ID are required');
    }
    const response = await axiosInstance.get('/api/organisations/organisation-exists', {
      params: {
        abn,
        ndisId
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error checking if the organisation exists', error);
    throw error;
  }
};

export const getCurrentUserProfile = async () => {
  try {
    const response = await axiosInstance.get(`/api/organisations/current-user`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current user profile', error);
    throw error;
  }
};

export const getVersion = async () => {
  try {
    const response = await axiosInstance.get('/api/version');
    return response.data;
  } catch (error) {
    console.error('There was an error getting the version', error);
    throw error;
  }
};

export const getOrganisationDetails = async (organisationId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    const response = await axiosInstance.get(`/api/organisations/${organisationId}/details`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the organisation details', error);
    throw error;
  }
};

export const editOrganisationDetails = async (organisationId: string, data: OrganisationDetails) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    const response = await axiosInstance.put(`/api/organisations/${organisationId}/details`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error editing the organisation details', error);
    throw error;
  }
};

// Returns a list of all contacts across all areas (service provider contacts, participants, and external contacts) for the given organisation ID
export const listAllContacts = async (
  organisationId: string,
  filters: SelectedFilter[] | null,
  page: number,
  pageSize: number
): Promise<any> => {
  try {
    if (!organisationId)
      throw new Error('Organisation ID are required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }


    const response = await axiosInstance.get(`/api/organisations/${organisationId}/contacts`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service provider contacts for the organisation', error);
    throw error;
  }
};