import { PaginationBase } from "types";
import { NameAndID } from "./userProfiles";

export interface Payfile {
  id?: string;
  reference: string;
  createdDate: string;
  createdBy: NameAndID | string;
  deliveredPeriod: {
    startDate: string;
    endDate: string;
  }
  totalAmount: number;
  status: PayfileStatus;
}

export type PayfileStatus = 'Paid' | 'Extracted' | 'Ready To Extract';

export const payfileStatuses = [
  {
    label: 'Paid',
    value: 'Paid',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Extracted',
    value: 'Extracted',
    fontColor: '#5C068C',
    backgroundColor: '#5C068C26',
  },
  {
    label: 'Ready To Extract',
    value: 'Ready To Extract',
    fontColor: '#0057B2',
    backgroundColor: '#03A9F414',
  },
  {
    label: 'Pending',
    value: 'Pending',
    fontColor: '#EF6C00',
    backgroundColor: '#F57C0014',
  },
];

export type PayfileList = PaginationBase<Payfile>;