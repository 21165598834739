import React, { FC } from 'react';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { Action } from 'components/common/ActionsDropdown/types';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import { UpdateProps } from './types';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';

const Updates: FC<UpdateProps> = ({ selectedRowIds }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const updateActions: Action[] = [
    {
      label: `Mark all as paid (${selectedRowIds.length})`,
      function: (props) => alert('Mark all as paid'),
      isDisabled: selectedRowIds.length === 0,
      icon: AttachMoneyOutlinedIcon,
    },
    {
      label: `Mark all as unpaid (${selectedRowIds.length})`,
      function: (props) => alert('Mark all as unpaid'),
      isDisabled: selectedRowIds.length === 0,
      icon: MoneyOffOutlinedIcon,
    },
    {
      label: 'Revert selected Records',
      function: (props) => alert('Revert selected Records'),
      isDisabled: selectedRowIds.length === 0,
      icon: UndoOutlinedIcon,
      borderTop: true,
    }
  ];

  return (
    <ButtonWithDropdown
      color='secondary'
      label='Actions'
      isDisabled={selectedRowIds.length === 0}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName='user-profiles' actions={updateActions} />
      }
    />
  );
};

export default Updates;