import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AllContact, LookupSelectFieldOptions } from '../types';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { ServiceProviderContact } from 'types/dbSchema/serviceProviders';

const ServiceProviderContacts = ({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  handleOptionClick,
}: any) => {
  const [options, setOptions] = useState<LookupSelectFieldOptions[]>([]);

  // When the data is fetched, map the users to the options
  // The label is JSX so that we can customise the look of the options
  useEffect(() => {
    if (data) {
      const newOptions = data.pages.flatMap((page: any) =>
        page.items.map((serviceProviderContact: ServiceProviderContact) => ({
          value: {
            id: serviceProviderContact.id,
            fullName: serviceProviderContact.fullName,
            email: serviceProviderContact.email,
            mobile: serviceProviderContact.mobile,
            phone: serviceProviderContact.phone,
            serviceProvider: serviceProviderContact.serviceProvider,
            contactType: 'ServiceProvider',
          } as AllContact,
          label: <ServiceProviderContactsLabel value={serviceProviderContact} />,
        }))
      );
      setOptions(newOptions);
    }
  }, [data]);


  return (
    <Stack maxHeight='200px' overflow='auto' onScroll={(event: any) => {
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    }}>
      {options.map((option, index) => (
        <Stack key={index} onClick={() => handleOptionClick(option)} sx={{ cursor: 'pointer', minHeight: '36px', justifyContent: 'center', padding: '0 10px' }}>
          {option.label}
        </Stack>
      ))}
      {isFetchingNextPage && (
        <Stack alignItems='center' marginBottom='10px' >
          <CircularProgress sx={{ position: 'relative', width: '100%' }} />
        </Stack>
      )}
    </Stack>
  );
};

const ServiceProviderContactsLabel = ({ value }: any) => {
  return (
    <Stack direction='row' alignItems='center' padding='8px' gap='8px'>
      <Stack
        width='32px'
        height='32px'
        borderRadius='50%'
        bgcolor='#4B55631F'
        color='#4B55638F'
        justifyContent='center'
        alignItems='center'
      >
        <ContactPhoneOutlinedIcon sx={{ width: '20px', height: '20px' }} />
      </Stack>
      <Stack gap='4px'>
        <Typography variant='subtitle2' lineHeight='20px'>
          {value.fullName.firstName} {value.fullName.lastName}
        </Typography>
        <Typography variant='body2' lineHeight='20px'>
          {value.email}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ServiceProviderContacts;