import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import variables from 'styles/variables';
import { useAtom } from 'jotai';
import { closeModalAtom, modalStateAtom } from 'atoms/modalAtom';
import { SubmittedState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ProgressNote } from 'types/dbSchema/progressNotes';
import { DBAttachment } from 'types';
import Score from '../../../common/Score';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import { format, parseISO } from 'date-fns';
import { downloadAttachment, downloadMultipleAttachments } from 'utils/helpers';

export interface ViewProgressNotesProps {
  progressNote: ProgressNote;
}

const ViewProgressNotes: FC<ViewProgressNotesProps> = ({ progressNote }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const totalAttachmentSize = (progressNote.attachments?.reduce((acc, file) => acc + file.fileSize, 0) / 1048576).toFixed(2);

  const downloadAllFiles = () => {
    downloadMultipleAttachments(progressNote.attachments);
  };

  const handleFullScreen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: modalState.position === 'center' ? 'right' : 'center',
      component: ViewProgressNotes,
      props: {
        progressNote: progressNote
      }
    });
  };

  return (
    <Stack padding='32px' width={modalState.position === 'center' ? '80vw' : 'unset'} height={modalState.position === 'center' ? '80vh' : 'unset'} overflow='auto'>
      <Stack gap='16px'>
        <Stack gap='16px' flexDirection='row' justifyContent='space-between'>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Created by</Typography>
            {/* TODO: When we know how the User is being handled. Is the user the complete user data, or a reference to the user that we need to make another api call to get? */}
            <Typography variant='subtitle2'>{progressNote.createdBy}</Typography>
          </Stack>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            {modalState.position === 'center' ?
              <CloseFullscreenOutlinedIcon onClick={handleFullScreen} sx={{ cursor: 'pointer' }} /> :
              <OpenInFullOutlinedIcon onClick={handleFullScreen} sx={{ cursor: 'pointer' }} />}
            <CloseOutlinedIcon onClick={closeModal} sx={{ cursor: 'pointer' }} />
          </Stack>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Shift Details</Typography>
          <Typography variant='body2'>
            {progressNote.createdDate ? format(parseISO(progressNote.createdDate), 'dd/MM/yyyy, hh:mm a') : ''}
          </Typography>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Note Type</Typography>
          <Typography variant='body2'>{progressNote.noteType}</Typography>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Score</Typography>
          <Score score={progressNote.score} />
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Linked Goal</Typography>
          <Typography variant='body2'>TODO</Typography>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='flex-start'>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Attachments</Typography>
          </Stack>
          <Stack>
            <Stack gap='8px' width='100%' alignContent='flex-start'>
              {progressNote.attachments?.map((file, index) => (
                <SubmittedState key={index} uploadedFile={file} />
              ))}
            </Stack>
            {/* <Stack flexDirection='row' justifyContent='flex-start' alignItems='center'>
              <Typography variant='subtitle2' color={variables.colors.text.secondary} fontSize='12px' fontWeight='500'>
                {progressNote.attachments.length} attachment{progressNote.attachments.length > 1 ? 's' : ''} ({totalAttachmentSize} MB)
              </Typography>
              <Button variant='text' onClick={downloadAllFiles} startIcon={<FileDownloadOutlinedIcon />} sx={{ width: 'fit-content', color: '#1F293799' }}>
                Download all
              </Button>
            </Stack> */}
          </Stack>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2'>{progressNote.note}</Typography>
        </Stack>
      </Stack>
    </Stack >
  );
};

export default ViewProgressNotes;