import { PaginationBase } from "types";
import { Participant } from "./participants";

export interface DeliveredServiceRecord {
  id?: string;
  reference: string;
  participant: Participant | string;
  supportItem: string;
  deliveredPeriod: {
    startDate: string;
    endDate: string;
  },
  quantity: number;
  rate: number;
  unit: string;
  total: number;
  status: DeliveredServiceRecordStatus;
}

export type DeliveredServiceRecordStatus = 'Paid' | 'Extracted' | 'Ready To Extract' | 'Pending';

export const deliveredServiceRecordStatuses = [
  {
    label: 'Paid',
    value: 'Paid',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Extracted',
    value: 'Extracted',
    fontColor: '#5C068C',
    backgroundColor: '#5C068C26',
  },
  {
    label: 'Ready To Extract',
    value: 'Ready To Extract',
    fontColor: '#0057B2',
    backgroundColor: '#03A9F414',
  },
  {
    label: 'Pending',
    value: 'Pending',
    fontColor: '#EF6C00',
    backgroundColor: '#F57C0014',
  },
];

export type DeliveredServiceFileList = PaginationBase<DeliveredServiceRecord>;