import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import StatusDropdown from 'components/common/StatusDropdown';
import { NameAndID } from 'types/dbSchema/userProfiles';
import variables from 'styles/variables';
import { formatISODateTime } from 'utils/helpers';
import { ndisClaimsStatuses } from 'types/dbSchema/ndisClaims';
import { format } from 'date-fns';
import ViewIcon from './ViewIcon';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon ndisClaim={params.row} />
    ),
  },
  {
    field: 'reference',
    headerName: 'Reference',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.reference}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.row?.createdDate && formatISODateTime(params.row?.createdDate)}
      </Typography>
    ),
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    minWidth: 150,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {(params.row.createdBy as NameAndID)?.fullName}
      </Typography>
    ),
  },
  {
    field: 'deliveredPeriod',
    headerName: 'Delivered Period',
    width: 250,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {`${format(params.row?.deliveredPeriod.startDate, 'dd/MM/yyyy')} - ${format(params.row?.deliveredPeriod.endDate, 'dd/MM/yyyy')}`}
      </Typography>
    ),
  },
  {
    field: 'totalAmount',
    headerName: 'Total Amount',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {`$${params.row.totalAmount.toFixed(2)}`}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    renderCell: (params) => (
      <StatusDropdown
        statuses={ndisClaimsStatuses}
        currentStatus={params.row.status}
        disabled
      />
    ),
  },
];