import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import LinkToGoalForm from 'components/features/Forms/LinkToGoalForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { listParticipantProgressNotes } from 'api/organisations/participants/progressNotes';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

const ProgressNotesDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const handleLinkToGoal = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: LinkToGoalForm,
      props: {
        selectedRowIds: selectedRowIds,
        participantId: id as string,
        dataTableName: 'participant-goals'
      }
    });
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-progress-notes', paginationModel, selectedFilters],
    queryFn: () => listParticipantProgressNotes(
      organisations[0].organisation.globalId,
      id as string,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.progressNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Progress Notes'
        dataTableName='participant-progress-notes'
        checkboxSelection
        paginationMode='server'
        onNoData={handleLinkToGoal}
      />
    </Stack>
  );
};

export default ProgressNotesDataTable;