import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import ParticipantFilter from 'components/common/FilterLookup/ParticipantFilter';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { deliveredServiceStatusFilter } from 'utils/filters';
import { useNavigate } from 'react-router-dom';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import Updates from './Updates';
import { ndisClaimsStatuses } from 'types/dbSchema/ndisClaims';
import { ControlsProps } from '../types';
import { format } from 'date-fns';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds,
  ndisClaimFile
}) => {
  const [filterOpen, setFilterOpen] = useState('');
  const navigate = useNavigate();

  const statusObject = ndisClaimsStatuses.find(status => status.value === ndisClaimFile.status);

  return (
    <StyledStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate('/billing/ndis-claims')}
      >
        <ArrowBackOutlinedIcon sx={{ padding: '4px', boxSizing: 'border-box', height: '24px', width: '24px' }} />
        NDIS Claims
      </Button>
      <InvoiceBatchOverview>
        <Stack flexDirection='row' gap='48px'>
          <Stack gap='4px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Reference
            </Typography>
            <Typography variant='body1'>
              {ndisClaimFile?.reference}
            </Typography>
          </Stack>
          <Stack gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Delivered Period
            </Typography>
            <Typography variant='body2'>
              {`${format(ndisClaimFile.deliveredPeriod.startDate, 'dd/MM/yyyy')} - ${format(ndisClaimFile.deliveredPeriod.endDate, 'dd/MM/yyyy')}`}
            </Typography>
          </Stack>
          <Stack gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Status
            </Typography>
            {statusObject && (
              <Box sx={{ backgroundColor: statusObject.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
                <Typography variant='body2' fontWeight='600' color={statusObject.fontColor}>
                  {statusObject.label}
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack flexDirection='row' gap='48px'>
          <Stack gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Total Amount
            </Typography>
            <Typography variant='body1' fontSize='18px' fontWeight='600'>
              ${ndisClaimFile?.totalAmount.toFixed(2)}
            </Typography>
          </Stack>
          <Stack gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Total Paid
            </Typography>
            <Typography variant='body1' fontSize='18px' fontWeight='600' color={variables.colors.success.main}>
              ${ndisClaimFile?.totalAmount.toFixed(2)}
            </Typography>
          </Stack>
        </Stack>
      </InvoiceBatchOverview>
      <Stack justifyContent='space-between' height='100%' gap='16px' flexDirection='row' alignItems='center' width='100%' marginTop='16px'>
        <Filters>
          <FilterWithDropdown
            filter={deliveredServiceStatusFilter}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
          />
          <ParticipantFilter
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            label='Participants'
          />
          {selectedFilters.length > 0 &&
            <ResetFiltersBox onClick={() => setSelectedFilters([])}>
              <Typography variant='body2' fontWeight='600' color='secondary' >
                Reset filters
              </Typography>
            </ResetFiltersBox>
          }
        </Filters>
        <Stack flexDirection='row' gap='16px' minWidth='fit-content'>
          <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' />
          <Updates selectedRowIds={selectedRowIds ? selectedRowIds : []} />
          <Actions selectedRowIds={selectedRowIds ? selectedRowIds : []} dataTableName='user-profile' />
        </Stack>
      </Stack>
    </StyledStack>
  );
};

const Filters = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 16px 24px 32px 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

const InvoiceBatchOverview = styled(Stack)`
  padding: 24px 32px;
  border-radius: 8px;
  border: 1px solid ${variables.colors.border.main};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default Controls;