import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import Tooltip from 'components/common/Tooltip';
import { ControlsProps } from '../../types';
import CreatedByFilter from 'components/common/FilterLookup/CreatedByFilter';
import ProgressNoteTypeFilter from 'components/common/FilterLookup/ProgressNoteTypeFilter';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds,
}) => {
  const [filterOpen, setFilterOpen] = useState('');

  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Progress Notes
          </Typography>
          <Stack flexDirection='row' gap='16px' height='42px'>
            <Filters>
              <CreatedByFilter
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
              <ProgressNoteTypeFilter
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
              {selectedFilters.length > 0 &&
                <ResetFiltersBox onClick={() => setSelectedFilters([])}>
                  <Typography variant='body2' fontWeight='600' color='secondary' >
                    Reset filters
                  </Typography>
                </ResetFiltersBox>
              }
            </Filters>
            <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' height='100%' />
            <Actions selectedRowIds={selectedRowIds ? selectedRowIds : []} dataTableName='participant-progress-notes' />
          </Stack>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const Filters = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-popper {
    background-color: white;
  }
`;

export default Controls;