import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import { Stack, Typography } from '@mui/material';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import variables from 'styles/variables';
import { UnlinkIcon } from './UnlinkIcon';
import Label from 'components/common/Label';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon contact={params.row} />
    ),
  },
  {
    field: 'unlink',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <UnlinkIcon contact={params.row} />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 300,
    renderCell: (params) => (
      <Stack>
        <Typography variant='body2' fontWeight='600'>
          {params.row.name}
        </Typography>
        <Typography color={variables.colors.text.secondary} variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.email}
        </Typography>
      </Stack>
    )
  },
  {
    field: 'relationship',
    headerName: 'Relationship',
    width: 250,
    renderCell: (params) => (
      <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.row.relationship.name}
      </Typography>
    )
  },
  {
    field: 'phoneMobile',
    headerName: 'Phone/Mobile',
    width: 220,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.phone}/{params.row.mobile}
      </Typography>
    )
  },
  {
    field: 'Contact Tags',
    headerName: 'Contact Tags',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Stack direction='row' gap='8px'>
        {params.row.isPrimaryContact && <Label text='Primary' />}
        {params.row.isEmergencyContact && <Label text='Emergency' />}
      </Stack>
    )
  },
];