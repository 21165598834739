import React, { FC, useEffect } from 'react';
import ViewUserProfile from 'components/features/Views/ViewUserProfile';
import { LinearProgress } from '@mui/material';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';

const ViewUserProfilePage: FC = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewUserProfile userProfile={currentDataPoint} />;
};

export default ViewUserProfilePage;