import { updatePosition } from 'api/organisations/positions';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { ServiceType } from 'types/dbSchema/schedulingSettings';
import { UserPosition } from 'types/dbSchema/userPositions';

export interface UserPositionFormProps {
  positionToEdit: UserPosition;
}

const EditIsActive: FC<UserPositionFormProps> = ({ positionToEdit }) => {
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();

  const handleEditPosition = async (changeStatusTo: boolean) => {
    try {
      const data = {
        ...positionToEdit,
        serviceTypesDelivered: (positionToEdit.serviceTypesDelivered as ServiceType[]).map((serviceType) => serviceType.id),
        isActive: changeStatusTo
      } as UserPosition;
      const response = await updatePosition(organisations[0].organisation.globalId, data);
      if (response) {
        setInvalidateData((prev) => ({ ...prev, 'user-position': true }));
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={positionToEdit.isActive}
      setIsActive={handleEditPosition}
    />
  );
};

export default EditIsActive;