import { OutlinedInput, FormControl, InputAdornment } from '@mui/material';
import React, { FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import variables from 'styles/variables';
import { SearchInputProps } from './types';

const SearchInput: FC<SearchInputProps> = ({
  searchValue,
  setSearchValue,
  placeholderText,
  onSearchValueChange,
  onClear,
  error,
}) => {

  const handleClear = () => {
    onClear && onClear();
    setSearchValue('');
  };

  return (
    <FormControl
      variant='outlined'
      sx={{
        height: '42px',
        width: '100%',
        backgroundColor: 'white'
      }}>
      <OutlinedInput
        sx={{ height: '100%', fontSize: '14px' }}
        placeholder={placeholderText}
        id='search-bar-input'
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchValueChange(e.target.value, e)}
        error={error}
        endAdornment={
          <InputAdornment position='end'>
            {searchValue.length === 0 ?
              <SearchIcon sx={{ color: variables.colors.darkNeutral.darker }} /> :
              <CloseOutlinedIcon onClick={handleClear} sx={{ color: variables.colors.darkNeutral.darker, cursor: 'pointer' }} />
            }
          </InputAdornment>}
        inputProps={{ 'aria-label': 'search-bar-input', 'data-testid': 'search-bar-input' }}
      />
    </FormControl>
  );
};

export default SearchInput;