import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import { LookupFieldProps, LookupSelectFieldOptions } from './types';
import Tabs from 'components/common/Tabs';
import AllContacts from './AllContacts';
import ExternalContacts from './ExternalContacts';
import Participants from './Participants';
import SearchInput from './SearchInput';
import { useInfiniteQuery } from '@tanstack/react-query';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { listAllServiceProviderContacts } from 'api/organisations/serviceproviders/serviceprovidercontacts';
import { listExternalContacts } from 'api/organisations/externalcontacts';
import { listParticipants } from 'api/organisations/participants';
import ServiceProviderContacts from './ServiceProviders';
import { listAllContacts } from 'api';
import { SelectedFilter } from 'components/common/FilterLookup/types';

const SearchAllContactsField = forwardRef<HTMLDivElement, LookupFieldProps>(({
  errorText,
  helperText,
  placeholder,
  validationError,
  onChange,
  ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropUp, setDropUp] = useState(false);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [totals, setTotals] = useState<{ [key: string]: number }>({
    all: 0,
    contacts: 0,
    participants: 0,
    serviceProviders: 0,
  });
  const [organisations] = useUserOrganisations();

  // TODO: Update this to reflect the actual filters
  const selectedFilters = [{
    field: "Email",
    valuesSelected: [searchValue]
  }] as SelectedFilter[];

  const allQuery = useInfiniteQuery({
    queryKey: ['all-contacts-infinite', organisations[0]?.organisation.globalId, 100, searchValue],
    queryFn: ({ pageParam = 0 }) => listAllContacts(organisations[0].organisation.globalId, selectedFilters, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.externalContacts,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) return undefined; // No more pages to load
      return allPages.length;
    },
  });

  const externalQuery = useInfiniteQuery({
    queryKey: ['external-contacts-infinite', organisations[0]?.organisation.globalId, 100, searchValue],
    queryFn: ({ pageParam = 0 }) => listExternalContacts(organisations[0].organisation.globalId, selectedFilters, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.externalContacts,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) return undefined; // No more pages to load
      return allPages.length;
    },
  });

  const participantsQuery = useInfiniteQuery({
    queryKey: ['participants-infinite', searchValue],
    queryFn: ({ pageParam = 0 }) => listParticipants(organisations[0].organisation.globalId, selectedFilters, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.externalContacts,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) return undefined; // No more pages to load
      return allPages.length;
    },
  });

  const serviceProvidersQuery = useInfiniteQuery({
    queryKey: ['service-provider-contacts-infinite', searchValue],
    queryFn: ({ pageParam = 0 }) => listAllServiceProviderContacts(organisations[0].organisation.globalId, selectedFilters, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.externalContacts,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) return undefined; // No more pages to load
      return allPages.length;
    },
  });

  // Update totals
  useEffect(() => {
    if (allQuery.data) {
      setTotals(prev => ({ ...prev, all: allQuery.data.pages[0].totalCount }));
    }
    if (externalQuery.data) {
      setTotals(prev => ({ ...prev, contacts: externalQuery.data.pages[0].totalCount }));
    }
    if (participantsQuery.data) {
      setTotals(prev => ({ ...prev, participants: participantsQuery.data.pages[0].totalCount }));
    }
    if (serviceProvidersQuery.data) {
      setTotals(prev => ({ ...prev, serviceProviders: serviceProvidersQuery.data.pages[0].totalCount }));
    }
  }, [allQuery.data, externalQuery.data, participantsQuery.data, serviceProvidersQuery.data]);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const boundingRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - boundingRect.bottom;

      if (spaceBelow < 20) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
    }
  }, [isOpen, searchValue]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setDropUp(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (optionValue: LookupSelectFieldOptions) => {
    onChange(optionValue.value);
    setIsOpen(false);
    setDropUp(false);
  };

  const handleSearchInputChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSearchValue(value);
    if (value.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setDropUp(false);
    }
  };

  return (
    <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }} {...props}>
      <SearchInput
        placeholderText={placeholder || ''}
        searchValue={searchValue}
        onSearchValueChange={handleSearchInputChange}
        setSearchValue={setSearchValue}
        error={validationError}
      />
      {isOpen && (
        <Dropdown ref={dropdownRef} dropUp={dropUp}>
          <Tabs
            useHashLocation={false}
            tabPadding='0'
            tabs={[
              {
                label: `All (${totals.all})`,
                content: (
                  <AllContacts
                    data={allQuery.data}
                    fetchNextPage={allQuery.fetchNextPage}
                    hasNextPage={allQuery.hasNextPage}
                    isFetchingNextPage={allQuery.isFetchingNextPage}
                    handleOptionClick={handleOptionClick}
                  />
                ),
                requiredPermissions: [],
              },
              {
                label: `Contacts (${totals.contacts})`,
                content: (
                  <ExternalContacts
                    data={externalQuery.data}
                    fetchNextPage={externalQuery.fetchNextPage}
                    hasNextPage={externalQuery.hasNextPage}
                    isFetchingNextPage={externalQuery.isFetchingNextPage}
                    handleOptionClick={handleOptionClick}
                  />
                ),
                requiredPermissions: [],
              },
              {
                label: `Participants (${totals.participants})`,
                content: (
                  <Participants
                    data={participantsQuery.data}
                    fetchNextPage={participantsQuery.fetchNextPage}
                    hasNextPage={participantsQuery.hasNextPage}
                    isFetchingNextPage={participantsQuery.isFetchingNextPage}
                    handleOptionClick={handleOptionClick}
                  />
                ),
                requiredPermissions: [],
              },
              {
                label: `Service Providers (${totals.serviceProviders})`,
                content: (
                  <ServiceProviderContacts
                    data={serviceProvidersQuery.data}
                    fetchNextPage={serviceProvidersQuery.fetchNextPage}
                    hasNextPage={serviceProvidersQuery.hasNextPage}
                    isFetchingNextPage={serviceProvidersQuery.isFetchingNextPage}
                    handleOptionClick={handleOptionClick}
                  />
                ),
                requiredPermissions: [],
              },
            ]}
          />
        </Dropdown>
      )}
    </div>
  );
});

export interface DropdownProps {
  dropUp: boolean;
}

const Dropdown = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dropUp',
}) <DropdownProps>`
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  top: ${({ dropUp }) => dropUp ? 'unset' : '100%'};
  bottom: ${({ dropUp }) => dropUp ? '100%' : 'unset'};
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  height: 250px;
`;

SearchAllContactsField.displayName = 'SearchAllContactsField';

export default SearchAllContactsField;