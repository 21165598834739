import React, { FC } from 'react';
import OrganisationDetailsForm from 'components/features/Forms/OrganisationDetailsForm';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getOrganisationDetails } from 'api';
import { LinearProgress } from '@mui/material';

const EditOrganisationDetails: FC = () => {
  const [organisations] = useUserOrganisations();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getOrganisationDetails(organisations[0].organisation.globalId)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <OrganisationDetailsForm organisationDetails={currentDataPoint} />;
};

export default EditOrganisationDetails;