import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { NdisClaim } from 'types/dbSchema/ndisClaims';
import { useNavigate } from 'react-router-dom';
import { Payfile } from 'types/dbSchema/payfiles';

export interface ViewIconProps {
  payfile: Payfile;
}

const ViewIcon: FC<ViewIconProps> = ({ payfile }) => {
  const navigate = useNavigate();

  return (
    <StyledBox onClick={() => navigate(`/billing/payfiles/view/${payfile.id}`)}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;