import axiosInstance from "api/config";
import { ServiceProvider, ServiceProviderContact, ServiceProviderList } from "types/dbSchema/serviceProviders";
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { createFilterString } from "utils/helpers";
import axios from "axios";

// Returns a list of service provider contacts for the given organisation ID and service provider ID
export const listServiceProviderContacts = async (
  organisationId: string,
  serviceProviderId: string,
  filters: SelectedFilter[] | null,
  page: number,
  pageSize: number
): Promise<ServiceProviderList> => {
  try {
    if (!organisationId || !serviceProviderId)
      throw new Error('Organisation ID, and ServiceProvider ID are required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }


    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/serviceprovidercontacts`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service provider contacts for the organisation', error);
    throw error;
  }
};

// Returns a list of service provider contacts for the given organisation ID
export const listAllServiceProviderContacts = async (
  organisationId: string,
  filters: SelectedFilter[] | null,
  page: number,
  pageSize: number
): Promise<ServiceProviderList> => {
  try {
    if (!organisationId)
      throw new Error('Organisation ID are required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }


    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceprovidercontacts`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service provider contacts for the organisation', error);
    throw error;
  }
};

// Returns a service provider contact by ID
export const getServiceProviderContactByID = async (
  organisationId: string,
  serviceProviderId: string,
  serviceProviderContactId: string
): Promise<ServiceProvider> => {
  try {
    if (!serviceProviderId || !organisationId || !serviceProviderContactId)
      throw new Error('Organisation ID, ServiceProvider ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.get(
      `/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/serviceprovidercontacts/${serviceProviderContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service provider contact', error);
    throw error;
  }
};

// Create a new service provider contact
export const createServiceProviderContact = async (
  organisationId: string,
  serviceProviderId: string,
  data: ServiceProvider,
) => {
  try {
    if (!organisationId || !data || !serviceProviderId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/serviceprovidercontacts/newcontact`, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // This will access the actual error response sent by the server
      console.error('There was an error creating the service provider contact contact', error.response.data);
      return error.response.data;
    } else {
      // Handle other unexpected errors
      console.error('Unexpected error', error);
      throw error;
    }
  }
};

// Update a service provider contact
export const updateServiceProviderContact = async (
  organisationId: string,
  serviceProviderId: string,
  serviceProviderContactId: string,
  data: ServiceProviderContact,
) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(
      `/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/serviceprovidercontacts/${serviceProviderContactId}`,
      data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service provider contact', error);
    throw error;
  }
};

// Delete a service provider contact
export const deleteServiceProviderContact = async (
  organisationId: string,
  serviceProviderId: string,
  serviceProviderContactId: string
) => {
  try {
    if (!organisationId || !serviceProviderId || !serviceProviderContactId)
      throw new Error('Organisation ID, ServiceProvider ID and ServiceProviderContact ID are required');

    const response = await axiosInstance.delete(
      `/api/organisations/${organisationId}/serviceproviders/${serviceProviderId}/serviceprovidercontacts/${serviceProviderContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the service provider contact', error);
    throw error;
  }
};