import React, { FC, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import TextInput from 'components/common/InputFields/TextInput';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useErrorMessage from 'hooks/useErrorMessage';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ServiceProviderContact } from 'types/dbSchema/serviceProviders';
import UserPositionsLookupField from 'components/common/LookupInputField/UserPositionLookupField';
import { createServiceProviderContact, updateServiceProviderContact } from 'api/organisations/serviceproviders/serviceprovidercontacts';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import variables from 'styles/variables';
import useCurrentUser from 'hooks/useCurrentUser';
import ServiceProviderPositionLookupField from 'components/common/LookupInputField/ServiceProviderPositionLookupField';
import { ServiceProviderPosition } from 'types/dbSchema/serviceProviderSettings';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';
import { phoneValidationRegex } from 'utils/helpers';

export interface ServiceProviderContactFormProps {
  serviceProviderContact?: ServiceProviderContact;
  serviceProviderId: string;
  triggerRefresh?: boolean;
}

const ServiceProviderContactForm: FC<ServiceProviderContactFormProps> = ({ serviceProviderContact, serviceProviderId, triggerRefresh }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const showError = useErrorMessage();
  const [currentUser] = useCurrentUser();
  const [isEdit, setIsEdit] = useState(false);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm<ServiceProviderContact>({
    mode: 'onChange',
    defaultValues: serviceProviderContact ? {
      ...serviceProviderContact,
      position: (serviceProviderContact?.position as ServiceProviderPosition)?.id as string || undefined,
    } : {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  useEffect(() => {
    serviceProviderContact ? setIsEdit(true) : setIsEdit(false);
  }, [serviceProviderContact]);

  const handleEditContact = async (data: any) => {
    try {
      await updateServiceProviderContact(organisations[0].organisation.globalId, serviceProviderId, data.id, data);
      setInvalidateData((prev) => ({ ...prev, 'service-provider-contacts': true }));
      triggerRefresh && setTriggerRefresh((prev) => prev + 1);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreateContact = async (data: any) => {
    try {
      const resp = await createServiceProviderContact(organisations[0].organisation.globalId, serviceProviderId, {
        ...data,
        createdBy: currentUser?.id
      });
      if (resp.success) {
        setInvalidateData((prev) => ({ ...prev, 'service-provider-contacts': true }));
        setTriggerRefresh((prev) => prev + 1);
        closeModal();
      } else {
        showError(resp.message);
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const submitForm = async (data?: Partial<ServiceProviderContact>) => {
    try {
      if (!data) return;
      setIsLoading(true);
      if (isEdit) {
        handleEditContact(data as ServiceProviderContact);
      } else {
        handleCreateContact(data);
      }
      setIsLoading(false);
    } catch (error) {
      showError((error as Error).message);
      setIsLoading(false);
    }
  };

  return (
    <form
      style={{
        position: 'relative',
        minWidth: '500px',
        width: '100vw',
        maxWidth: '800px',
        padding: '32px'
      }}
      onSubmit={handleSubmit((data) => submitForm(data))}
    >
      {isLoading && <LoadingOverlay sx={{ borderRadius: '12px' }} />}
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600' mb={2}>
          {isEdit ? 'Edit Contact' : 'Add New Contact'}
        </Typography>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="fullName.firstName"
            control={control}
            rules={{ required: 'First Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='firstName'
                label='First Name'
                error={errors.fullName?.firstName ? true : false}
                errorText={errors.fullName?.firstName?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="fullName.lastName"
            control={control}
            rules={{ required: 'Last Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='lastName'
                label='Description'
                error={errors.fullName?.lastName ? true : false}
                errorText={errors?.fullName?.lastName?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="position"
            control={control}
            rules={{
              required: 'Position is required'
            }}
            render={({ field }) =>
              <ServiceProviderPositionLookupField
                {...field}
                id='position'
                label='Position'
                placeholder='Select'
                validationError={errors.position ? true : false}
                errorText={errors.position?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='email'
                label='Email'
                error={errors.email ? true : false}
                errorText={errors?.email?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="mobile"
            control={control}
            rules={{
              required: 'Mobile is required',
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
              maxLength: { value: 10, message: 'Mobile number should be 10 digits' },
              minLength: { value: 10, message: 'Mobile number should be 10 digits' }
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='mobile'
                label='Mobile'
                error={errors.mobile ? true : false}
                errorText={errors?.mobile?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: 'Phone number is required',
              pattern: {
                value: phoneValidationRegex,
                message: 'Please enter a valid Australian phone number',
              },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='phone'
                label='Phone'
                error={errors.phone ? true : false}
                errorText={errors?.phone?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            Contact Tags
          </Typography>
          <Controller
            name="isPrimaryContact"
            control={control}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                id='isPrimaryContact'
                label="Primary Contact"
                error={errors.isPrimaryContact ? true : false}
                checked={field.value ? true : false}
              />}
          />
        </Stack>
        <Controller
          name="isActive"
          control={control}
          rules={{
            validate: value => value !== undefined && value !== null || 'Status is required'
          }}
          render={({ field }) =>
            <IsActiveDropdown
              isActive={(field.value === undefined || field.value === null) ? true : field.value}
              setIsActive={field.onChange}
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ServiceProviderContactForm;