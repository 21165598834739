import React, { FC } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { createDummyProgressNote } from 'api/organisations/participants/progressNotes';
import ProgressNoteTypesLookupField from 'components/common/LookupInputField/ProgressNoteTypesLookupField';
import { ProgressNote } from 'types/dbSchema/progressNotes';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useCurrentUser from 'hooks/useCurrentUser';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import TextInput from 'components/common/InputFields/TextInput';

export interface TestProgressNoteFormProps {
  participantId: string;
}

const TestProgressNoteForm: FC<TestProgressNoteFormProps> = ({ participantId }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const { control, handleSubmit, formState: { errors } } = useForm<ProgressNote>({ mode: 'onChange' });
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const handleLinkToGoal = async (data: any) => {
    try {
      await createDummyProgressNote(organisations[0].organisation.globalId, participantId, {
        ...data,
        createdBy: currentUser?.id as string,
        createdDate: new Date().toISOString(),
        attachments: [],
        linkedToGoalID: '',
        score: 1,
      });
      setInvalidateData((prev) => ({ ...prev, 'participant-progress-notes': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form style={{ width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => handleLinkToGoal(data))}>
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600' mb={2}>
          Create Test Progress Note
        </Typography>
        <Controller
          name="noteType"
          control={control}
          rules={{ required: 'Note Type is required' }}
          render={({ field }) =>
            <ProgressNoteTypesLookupField
              {...field}
              id='noteType'
              label='Note Type'
              validationError={errors.noteType ? true : false}
              errorText={errors.noteType?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="note"
          control={control}
          rules={{
            required: 'Note is required',
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='note'
              label='Note'
              error={errors.note ? true : false}
              errorText={errors.note?.message}
              isMandatory
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Create
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export interface EditProps {
  selectedRowId: string;
}

export const Edit: FC<EditProps> = ({ selectedRowId }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleImportModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: TestProgressNoteForm,
      props: {
        dataTableName: 'user-positions',
        selectedRowIds: [selectedRowId],
      }
    });
  };

  return (
    <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
      <EditOutlinedIcon
        sx={{ color: '#9CA3AF', cursor: 'pointer' }}
        onClick={handleImportModalOpen}
      />
    </Stack>
  );
};

export default TestProgressNoteForm;