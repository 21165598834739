import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import Score from '../../../../../common/Score';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';
import { formatISODateTime } from 'utils/helpers';
import UserProfileCell from 'components/features/UserProfileCell';
import { UnlinkIcon } from './UnlinkIcon';

export const Columns: GridColDef[] = [
  {
    field: 'unlink',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <UnlinkIcon progressNote={params.row} />
    ),
  },
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon progressNote={params.row} />
    ),
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 1,
    minWidth: 300,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'score',
    headerName: 'Score',
    width: 100,
    sortable: true,
    renderCell: (params) => (
      <Score score={params.row.score} />
    ),
  },
];