import React, { FC } from 'react';
import { Action } from 'components/common/ActionsDropdown/types';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { ActionsProps } from 'types';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ImportModal from 'components/features/ImportModal';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import { closeModalAtom } from 'atoms/modalAtom';

const Actions: FC<ActionsProps> = ({ selectedRowIds, dataTableName }) => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleImportModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ImportModal,
        props: {
          dataTableName: dataTableName,
        },
      });
    } else {
      closeModal();
    }
  };

  const actions: Action[] = [
    {
      icon: FileUploadOutlinedIcon,
      label: 'Extract NDIS Claim File',
      function: () => alert('Extract NDIS Claim File'),
    },
    {
      icon: FileUploadOutlinedIcon,
      label: 'Extract Xero CSV',
      function: () => alert('Extract Xero CSV'),
    },
  ];

  return (
    <ButtonWithDropdown
      color='primary'
      label='Extract File'
      isDisabled={false}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName={dataTableName} actions={actions} />
      }
    />
  );
};

export default Actions;