import React, { FC } from 'react';
import ViewServiceContracts from 'components/features/Views/ViewParticipant/ServiceContracts/ViewServiceContracts';
import { dummyServiceContracts } from 'utils/helpers/getDBData';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';

const ViewServiceContractsPage: FC = () => {
  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getServiceContractById });
  // if (isLoading) return <LinearProgress />;
  // if (hasError || !currentDataPoint) return null;

  return <ViewServiceContracts serviceContract={dummyServiceContracts[0]} />;
};

export default ViewServiceContractsPage;