import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { ViewServiceProviderProps } from 'components/features/Views/ViewServiceProvider/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import ServiceProviderNoteForm from 'components/features/Forms/ServiceProviderNoteForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import useInvalidateData from 'hooks/useInvalidateData';
import { listServiceProviderNotes } from 'api/organisations/serviceproviders/notes';

const ServiceProviderNotesDataTable: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('service-provider-notes');

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'right',
      component: ServiceProviderNoteForm,
      props: {
        dataTableName: 'service-provider-notes',
        type: 'create',
        serviceProviderId: id as string,
      }
    });
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['service-provider-notes', paginationModel],
    queryFn: () =>
      listServiceProviderNotes(
        organisations[0].organisation.globalId,
        id as string,
        paginationModel.page + 1,
        paginationModel.pageSize
      ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: organisations.length > 0,
    staleTime: cachingInvalidation.generalNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Notes'
        dataTableName='service-provider-notes'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default ServiceProviderNotesDataTable;