import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { format } from 'date-fns';
import ServiceType from 'components/common/ServiceType';
import { User } from 'types/dbSchema/userProfiles';
import { calculateDuration } from 'utils/helpers/fullCalendarTemplates';

export const Columns: GridColDef[] = [
  {
    field: 'serviceTeam',
    headerName: 'Service Team',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {`${(params.row.user as User).fullName.firstName} ${(params.row.user as User).fullName.lastName}`}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'employeeNo',
    headerName: 'Employee No',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {(params.row.user as User).employeeNo}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 200,
    renderCell: (params) => (
      <ServiceType serviceType={params.row.shift.serviceType} />
    ),
    sortable: false,
  },
  {
    field: 'shiftStartDate',
    headerName: 'Shift Start Date',
    width: 250,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {format(params.row?.shift.startTime, 'dd/MM/yyyy')}
      </Typography>
    ),
  },
  {
    field: 'totalHours',
    headerName: 'Total Hours',
    width: 100,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {calculateDuration(params.row?.shift.startTime, params.row?.shift.endTime)}
      </Typography>
    ),
  },
  {
    field: 'totalPay',
    headerName: 'Total Pay',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {/* {params.row.shift.total.toFixed(2)} */}
      </Typography>
    ),
  },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   width: 200,
  //   renderCell: (params) => (
  //     <StatusDropdown
  //       statuses={deliveredServiceRecordStatuses}
  //       currentStatus={params.row.shift.status}
  //       disabled
  //     />
  //   ),
  // },
];