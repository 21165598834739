import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';
import { isValidABN, phoneValidationRegex } from 'utils/helpers';
import { ServiceType as IServiceType } from 'types/dbSchema/schedulingSettings';
import ServiceType from 'components/common/ServiceType';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import ServiceTypesMultiSelectLookupField from 'components/common/MultiSelectLookupField/ServiceTypesMultiSelectLookupField';

export interface CompanyDetailsProps {
  control: Control<ServiceProvider>;
  errors: FieldErrors<ServiceProvider>;
}

const CompanyDetails: FC<CompanyDetailsProps> = ({
  control,
  errors,
}) => {

  return (
    <DetailsStack>
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6' fontWeight='600' color='text.primary'>
          Company Details
        </Typography>
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Row>
        <Controller
          name="companyName"
          control={control}
          rules={{
            required: 'Company Name is required',
            pattern: { value: /^[a-zA-Z0-9\s- ]+$/, message: 'Company Name must only contain letters, numbers, spaces and hyphens' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label='Company Name'
              error={errors.companyName ? true : false}
              errorText={errors.companyName?.message}
              isMandatory
            />}
        />
        <Controller
          name="abn"
          control={control}
          rules={{
            validate: {
              correctFormat: value => !value || isValidABN(value) || 'ABN is invalid', // Custom validation rule
            }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='abn'
              label='ABN'
              error={errors.abn ? true : false}
              errorText={errors.abn?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="serviceTypes"
          control={control}
          render={({ field }) =>
            <ServiceTypesMultiSelectLookupField
              {...field}
              value={field.value as string[]}
              id='serviceTypes'
              label='Service Types'
              validationError={errors.serviceTypes ? true : false}
              errorText={errors.serviceTypes?.message}
              placeholder='Select'
            />}
        />
      </Row>
      <Typography variant='subtitle1' fontWeight='600'>
        Contact Details
      </Typography>
      <Row>
        <Controller
          name="phone"
          control={control}
          rules={{
            pattern: {
              value: phoneValidationRegex,
              message: 'Please enter a valid Australian phone number',
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label='Phone Number'
              error={errors.phone ? true : false}
              errorText={errors.phone?.message}
            />}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Email Address is invalid' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='emailAddress'
              label='Email'
              error={errors.email ? true : false}
              errorText={errors.email?.message}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="website"
          control={control}
          render={({ field }) =>
            <TextInput
              {...field}
              id='website'
              label='Website'
              error={errors.website ? true : false}
              errorText={errors.website?.message}
            />}
        />
      </Row>
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default CompanyDetails;