import { Button, Stack, Typography } from '@mui/material';
import DeliveredServiceRecordDataTable from 'components/features/DataTables/DeliveredServiceRecordDataTable';
import React from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { AddOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const CreateClaimFile = () => {
  const navigate = useNavigate();

  const handleCancel = () => navigate('/billing/ndis-claims');

  const handleSubmit = () => alert('Create Claim File');

  return (
    <Stack height='100%' width='100%' justifyContent='center' alignItems='center'>
      <Banner>
        <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
          <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
          <Typography variant='h5'>
            Create Claim File
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px'>
          <Button
            variant='outlined'
            sx={{ color: 'white', border: '1px solid white' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            type="submit"
            sx={{
              color: 'white',
              backgroundColor: variables.colors.secondary.main,
              ':hover': { backgroundColor: variables.colors.secondary.darker }
            }}
            onClick={handleSubmit}
          >
            Create File
          </Button>
        </Stack>
      </Banner>
      <DeliveredServiceRecordDataTable />
    </Stack>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: relative;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

export default CreateClaimFile;