import { LinearProgress } from '@mui/material';
import ViewBatchFile from 'components/features/Views/ViewBatchFile';
import ViewPayfile from 'components/features/Views/ViewPayfile';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import useUserOrganisations from 'hooks/useUserOrganisations';
import React from 'react';
import { useParams } from 'react-router-dom';
import { dummyPayfiles } from 'utils/helpers/getDBData';

const ViewPayfilePage = () => {
  // TODO: implement the below with the correct API call
  // const { id } = useParams();
  // const [organisations] = useUserOrganisations();

  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
  //   getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string)
  // });

  // if (isLoading) return <LinearProgress />;
  // if (hasError || !currentDataPoint) return null;

  // return <ViewBatchFile invoiceBatchFile={currentDataPoint} />;
  return <ViewPayfile payfile={dummyPayfiles[0]} />;
};

export default ViewPayfilePage;