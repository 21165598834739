import { Box, Stack, Tab, Tabs as TabsMUI } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { TabsProps } from './types';
import useCurrentUser from 'hooks/useCurrentUser';
import { useLocation, } from 'react-router-dom';

const Tabs: FC<TabsProps> = ({
  tabs,
  tabPadding = '0 24px',
  useHashLocation = true,
}) => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const [visibleTabs, setVisibleTabs] = useState(tabs);
  const [user] = useCurrentUser();
  const location = useLocation();
  const hash = location.hash.replace('#', '');

  // Filter out tabs that the user does not have permission to view
  useEffect(() => {
    setVisibleTabs(tabs.filter((tab) => {
      return tab.requiredPermissions.every((permission) => user?.permissions.includes(permission));
    }));
  }, [tabs, user]);

  useEffect(() => {
    if (!useHashLocation) return;
    const tabIndex = visibleTabs.findIndex((tab) => tab.label.toLowerCase().replaceAll(' ', '-') === hash);
    if (tabIndex !== -1) {
      setCurrentTab(tabIndex);
    } else {
      setCurrentTab(0);
    }
  }, [hash, visibleTabs]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setCurrentTab(newValue);
    if (!useHashLocation) return;
    const newHash = visibleTabs[newValue]?.label.toLowerCase().replaceAll(' ', '-');
    window.location.hash = newHash;
  };

  if (!user || !visibleTabs) return null;
  if (currentTab === null) return null;

  return (
    <Stack width='100%' height='100%'>
      <Box sx={{ backgroundColor: 'white', padding: tabPadding }}>
        <Stack flexDirection='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabsMUI
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '.MuiTabs-scroller': {
                overflow: 'auto !important',
                scrollbarWidth: 'none',
              },
            }}>
            {visibleTabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                onClick={() => setCurrentTab(index)}
                sx={{ fontWeight: '600' }}
              />
            ))}
          </TabsMUI>
        </Stack>
      </Box>
      {/* NOTE: Content will handle it's own padding */}
      <Stack padding='0' sx={{ width: '100%', height: '100%' }}>
        {visibleTabs[currentTab].content}
      </Stack>
    </Stack>
  );
};

export default Tabs;